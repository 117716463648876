import { Component, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

import { AuthService } from '../../core/auth/auth.service';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-main-menu',
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  authService = inject(AuthService);
  menuService = inject(MenuService);
  router = inject(Router);

  logout() {
    this.authService.doLogout();
    // this.router.navigate(['home']);
  }
}
