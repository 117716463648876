import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import docsGreyIcon from 'src/assets//images/icons/icon_docs_grey.svg';
import docsWhiteIcon from 'src/assets//images/icons/icon_docs_white.svg';
import menuIcon from 'src/assets/images/global/icon_menu.svg';
import addBlueIcon from 'src/assets/images/icons/icon_add_blue.svg';
import affiliatesIcon from 'src/assets/images/icons/icon_affiliates.svg';
import appGreyIcon from 'src/assets/images/icons/icon_app_grey.svg';
import appWhiteIcon from 'src/assets/images/icons/icon_app_white.svg';
import applicationsIcon from 'src/assets/images/icons/icon_applications.svg';
import archiveGreyIcon from 'src/assets/images/icons/icon_archive_grey.svg';
import archiveWhiteIcon from 'src/assets/images/icons/icon_archive_white.svg';
import brokerGreyIcon from 'src/assets/images/icons/icon_broker_grey.svg';
import checkMarkGreenIcon from 'src/assets/images/icons/icon_checkmark-circle_green.svg';
import closeBlueIcon from 'src/assets/images/icons/icon_close_blue.svg';
import closeRedIcon from 'src/assets/images/icons/icon_close_red.svg';
import companyGreyIcon from 'src/assets/images/icons/icon_company_grey.svg';
import docsBlueIcon from 'src/assets/images/icons/icon_docs_blue.svg';
import editBlueIcon from 'src/assets/images/icons/icon_edit_blue.svg';
import emailBlueIcon from 'src/assets/images/icons/icon_email_blue.svg';
import emailGreyIcon from 'src/assets/images/icons/icon_email_grey.svg';
import emailWhiteIcon from 'src/assets/images/icons/icon_email_white.svg';
import funderGreyIcon from 'src/assets/images/icons/icon_funder_grey.svg';
import funderWhiteIcon from 'src/assets/images/icons/icon_funder_white.svg';
import historyGreyIcon from 'src/assets/images/icons/icon_history_grey.svg';
import historyWhiteIcon from 'src/assets/images/icons/icon_history_white.svg';
import infoGreyIcon from 'src/assets/images/icons/icon_info_grey.svg';
import insuranceGreyIcon from 'src/assets/images/icons/icon_insurance_grey.svg';
import insuranceWhiteIcon from 'src/assets/images/icons/icon_insurance_white.svg';
import leadsGreyIcon from 'src/assets/images/icons/icon_leads_grey.svg';
import lightningGreyIcon from 'src/assets/images/icons/icon_lightning_grey.svg';
import linkGreyIcon from 'src/assets/images/icons/icon_link_grey.svg';
import linkWhiteIcon from 'src/assets/images/icons/icon_link_white.svg';
import locationGreyIcon from 'src/assets/images/icons/icon_location_grey.svg';
import phoneGreyIcon from 'src/assets/images/icons/icon_mobile-phone_grey.svg';
import mobileGreyIcon from 'src/assets/images/icons/icon_mobile-phone_grey.svg';
import notesGreyIcon from 'src/assets/images/icons/icon_notes_grey.svg';
import notesWhiteIcon from 'src/assets/images/icons/icon_notes_white.svg';
import profileGreyIcon from 'src/assets/images/icons/icon_profile_grey.svg';
import profileWhiteIcon from 'src/assets/images/icons/icon_profile_white.svg';
import receivablesIcon from 'src/assets/images/icons/icon_receivables.svg';
import searchIcon from 'src/assets/images/icons/icon_search_black.svg';
import securityGreyIcon from 'src/assets/images/icons/icon_security_grey.svg';
import securityWhiteIcon from 'src/assets/images/icons/icon_security_white.svg';
import sendWhiteIcon from 'src/assets/images/icons/icon_send_white.svg';
import settingsBlueIcon from 'src/assets/images/icons/icon_settings_blue.svg';
import textBlueIcon from 'src/assets/images/icons/icon_text_blue.svg';
import usersGreyIcon from 'src/assets/images/icons/icon_users_grey.svg';

// This code block is used to update the class names of the SVG icons in order to avoid
// collisions with other SVG icons in the application.
const clsIds = ['cls-1', 'cls-2', 'cls-3', 'cls-4'];
const encapsulateClassNames = (svgString: string) => {
  let newSvgString = svgString;
  for (const clsId of clsIds) {
    const newClassName = `${clsId}-${getNewIndex()}`;
    // Assign the new class name to the SVG icon with the specified class ID
    newSvgString = newSvgString.replaceAll(clsId, newClassName);
  }
  return newSvgString;
};
let updateIndex = 0;

function getNewIndex() {
  return ++updateIndex;
}

// End of code block

@Injectable({ providedIn: 'root' })
export class CustomIconsService {
  private iconRegistry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);

  icons = [
    { name: 'settingsBlue', svg: settingsBlueIcon },
    { name: 'appWhite', svg: appWhiteIcon },
    { name: 'appGrey', svg: appGreyIcon },
    { name: 'linkGrey', svg: linkGreyIcon },
    { name: 'linkWhite', svg: linkWhiteIcon },
    { name: 'insuranceGrey', svg: insuranceGreyIcon },
    { name: 'insuranceWhite', svg: insuranceWhiteIcon },
    { name: 'docsWhite', svg: docsWhiteIcon },
    { name: 'docsGrey', svg: docsGreyIcon },
    { name: 'funderWhite', svg: funderWhiteIcon },
    { name: 'funderGrey', svg: funderGreyIcon },
    { name: 'historyGrey', svg: historyGreyIcon },
    { name: 'historyWhite', svg: historyWhiteIcon },
    { name: 'notesWhite', svg: notesWhiteIcon },
    { name: 'notesGrey', svg: notesGreyIcon },
    { name: 'profileWhite', svg: profileWhiteIcon },
    { name: 'profileGrey', svg: profileGreyIcon },
    { name: 'securityWhite', svg: securityWhiteIcon },
    { name: 'securityGrey', svg: securityGreyIcon },
    { name: 'emailWhite', svg: emailWhiteIcon },
    { name: 'emailGrey', svg: emailGreyIcon },
    { name: 'sendWhite', svg: sendWhiteIcon },
    { name: 'lightningGrey', svg: lightningGreyIcon },
    { name: 'archiveWhite', svg: archiveWhiteIcon },
    { name: 'archiveIcon', svg: archiveGreyIcon },
    { name: 'addBlue', svg: addBlueIcon },
    { name: 'textBlue', svg: textBlueIcon },
    { name: 'emailBlue', svg: emailBlueIcon },
    { name: 'editBlue', svg: editBlueIcon },
    { name: 'docsBlue', svg: docsBlueIcon },
    { name: 'leadsGrey', svg: leadsGreyIcon },
    { name: 'phoneGrey', svg: phoneGreyIcon },
    { name: 'checkMarkGreen', svg: checkMarkGreenIcon },
    { name: 'closeRed', svg: closeRedIcon },
    { name: 'closeBlue', svg: closeBlueIcon },
    { name: 'searchBlack', svg: searchIcon },
    { name: 'menu', svg: menuIcon },
    { name: 'companyGrey', svg: companyGreyIcon },
    { name: 'brokerGrey', svg: brokerGreyIcon },
    { name: 'usersGrey', svg: usersGreyIcon },
    { name: 'infoGrey', svg: infoGreyIcon },
    { name: 'locationGrey', svg: locationGreyIcon },
    { name: 'mobileGrey', svg: mobileGreyIcon },
    { name: 'affiliates', svg: affiliatesIcon },
    { name: 'applications', svg: applicationsIcon },
    { name: 'receivables', svg: receivablesIcon },
  ];

  registerIcons() {
    for (const icon of this.icons) {
      this.iconRegistry.addSvgIconLiteral(icon.name, this.sanitizer.bypassSecurityTrustHtml(encapsulateClassNames(icon.svg)));
    }
  }
}
