import { Routes } from '@angular/router';

import { authGuard } from './core/auth/auth.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  {
    path: 'home',
    title: 'netLoan Broker',
    loadComponent: () => import('./features/home/home.component').then(m => m.HomeComponent),
    pathMatch: 'full',
  },

  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routes').then(m => m.AuthRoutes),
  },

  // Application: used for the public public-application-form form.
  {
    path: 'app/:id',
    title: 'Application - netLoan Broker',
    loadComponent: () => import('./features/applications/features/public-application-form/public-application-form.component').then(m => m.PublicApplicationFormComponent),
    pathMatch: 'full',
  },

  {
    path: '',
    component: MainLayoutComponent,
    children: [
      /* DASHBOARD */
      {
        path: 'dashboard',
        title: 'netLoan Broker Dashboard',
        canActivate: [authGuard],
        loadComponent: () => import('./features/dashboard/dashboard').then(m => m.DashboardComponent),
      },

      /* ADMIN */
      {
        path: 'admin',
        canActivate: [authGuard],
        loadChildren: () => import('./features/admin/admin.routes').then(m => m.AdminRoutes),
      },

      /* ACCOUNT */
      {
        path: 'account',
        canActivate: [authGuard],
        loadChildren: () => import('./features/account/account.routes').then(m => m.AccountRoutes),
      },

      {
        path: 'apps',
        canActivate: [authGuard],
        loadChildren: () => import('./features/applications-search/applications-layout.routes').then(m => m.ApplicationLayoutRoutes),
      },

      /*APPLICATIONS*/
      {
        path: 'applications',
        canActivate: [authGuard],
        loadChildren: () => import('./features/applications-search/applications.routes').then(m => m.ApplicationRoutes),
      },

      /*LEAD*/
      {
        path: 'leads',
        canActivate: [authGuard],
        loadChildren: () => import('./features/leads/leads.routes').then(m => m.LeadsRoutes),
      },
    ],
  },
];
