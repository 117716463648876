import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MainMenuComponent } from '../main-menu/main-menu.component';
import { MainMenuMobileComponent } from '../main-menu-mobile/main-menu-mobile.component';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-main-layout',
  imports: [RouterOutlet, MainMenuMobileComponent, MainMenuComponent],
  template: `
    <div class="PageLoaderContainer"></div>

    <app-main-menu-mobile />

    <!------------------------------ Begin Main Container ------------------------------>
    <div
      class="MainContainer h-full"
      id="MainContainer"
      style="transition: transform 0.2s ease-in-out; transform: translateX(0px)"
      [style]="{
        transform: menuService.isMenuOpen() ? 'translateX(-260px)' : 'none',
      }">
      <!------------------------------ Begin Content Container ------------------------------>
      <div class="ContentContainer min-h-max h-full">
        <div class="NotificationBannerContainer Failure">Test Message</div>
        <div class="VersionContainer">Version - Beta 1.0</div>

        <!------------------------------ Begin Header ------------------------------>
        <div class="relative">
          <app-main-menu></app-main-menu>
        </div>

        <!------------------------------ Begin Page Container ------------------------------>
        <div class="PageContentContainer CCDivider h-max">
          <router-outlet />
        </div>
      </div>
    </div>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {
  protected menuService = inject(MenuService);
}
