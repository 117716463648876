<div class="PageLoaderContainer"></div>

<app-main-menu-mobile />

<!------------------------------ Begin Main Container ------------------------------>
<div class="MainContainer" id="MainContainer">
  <!------------------------------ Begin Content Container ------------------------------>
  <div class="ContentContainer">
    <div class="NotificationBannerContainer Failure">Test Message</div>
    <div class="VersionContainer">Version - Beta 1.0</div>

    <!------------------------------ Begin Header ------------------------------>
    <div class="HeaderContainer">
      <app-main-menu></app-main-menu>
      <div class="Clear"></div>
    </div>

    <!------------------------------ Begin Page Container ------------------------------>
    <div class="PageContentContainer CCDivider">
      <router-outlet />
    </div>
  </div>
</div>
