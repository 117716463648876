import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { CustomIconsService } from './core/custom-icons.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  template: '<router-outlet />',
})
export class AppComponent {
  constructor() {
    inject(CustomIconsService).registerIcons();
  }
}
