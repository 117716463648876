import { Routes } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';
import { AdminStatsStore } from './features/admin/admin-stats.store';
import { FeatureStatsStore } from './features/feature-stats.store';
import { AccountLayoutComponent } from './layouts/account-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  {
    path: 'home',
    title: 'netLoan Broker',
    loadComponent: () => import('./features/home/home.component').then(m => m.HomeComponent),
    pathMatch: 'full',
  },

  {
    path: 'login',
    title: 'Log into netLoan Broker',
    loadComponent: () => import('./features/account/login/login').then(m => m.LoginComponent),
    pathMatch: 'full',
  },
  {
    path: 'logout',
    loadComponent: () => import('./core/auth/logout').then(m => m.LogoutComponent),
    pathMatch: 'full',
  },
  {
    path: 'register',
    loadComponent: () => import('./features/account/registration/registration').then(m => m.RegistrationComponent),
    pathMatch: 'full',
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./features/account/forgot-password/forgot-password').then(m => m.ForgotPasswordComponent),
    pathMatch: 'full',
  },

  // Application: used for the public application form.
  {
    path: 'app/:id',
    title: 'Application - netLoan Broker',
    loadComponent: () => import('./features/apps/application/application.component').then(m => m.ApplicationComponent),
    pathMatch: 'full',
  },

  {
    path: '',
    component: MainLayoutComponent,
    children: [
      /* DASHBOARD */
      {
        path: 'dashboard',
        title: 'netLoan Broker Dashboard',
        canActivate: [AuthGuard],
        loadComponent: () => import('./features/dashboard/dashboard').then(m => m.DashboardComponent),
      },

      /* ADMIN */
      {
        path: 'admin',
        canActivate: [AuthGuard],
        providers: [AdminStatsStore],
        children: [
          /* AFFILIATES */
          {
            path: 'affiliates',
            loadComponent: () => import('./features/admin/affiliates/affiliates.component').then(m => m.AdminAffiliatesComponent),
          },

          /* BROKERS */
          {
            path: 'brokers',
            loadComponent: () => import('./features/admin/brokers/brokers.component').then(m => m.AdminBrokersComponent),
          },
          {
            path: 'new-broker',
            loadComponent: () => import('./features/admin/brokers/new-broker/new-broker.component').then(m => m.NewBrokerComponent),
          },
          {
            path: 'brokers/:id',
            loadComponent: () => import('./features/admin/brokers/new-broker/new-broker.component').then(m => m.NewBrokerComponent),
          },

          /* USERS */
          {
            path: 'users',
            loadComponent: () => import('./features/admin/users/users.component').then(m => m.AdminUsersComponent),
          },
          {
            path: 'users/:id',
            loadComponent: () => import('./features/admin/users/new-user/new-user.component').then(m => m.NewUserComponent),
          },
          {
            path: 'users/:id/edit',
            loadComponent: () => import('./features/admin/users/new-user/new-user.component').then(m => m.NewUserComponent),
          },
          {
            path: 'new-user',
            loadComponent: () => import('./features/admin/users/new-user/new-user.component').then(m => m.NewUserComponent),
          },

          /* LENDERS */
          {
            path: 'lenders',
            loadComponent: () => import('./features/admin/lenders/lenders.component').then(m => m.AdminLendersComponent),
          },

          /* CONTACTS */
          {
            path: 'contacts',
            canActivate: [AuthGuard],
            loadChildren: () => import('./features/admin/contacts/contacts.route').then(m => m.ContactRoutes),
          },
        ],
      },

      /* ACCOUNT */
      {
        path: 'account',
        canActivate: [AuthGuard],
        component: AccountLayoutComponent,
        // providers: [AdminSearchStore],
        children: [
          {
            path: 'info',
            loadComponent: () => import('./features/account/account-info/account-info.component').then(m => m.AccountInfoComponent),
          },
          {
            path: 'profile',
            loadComponent: () => import('./features/account/account-profile/account-profile.component').then(m => m.AccountProfileComponent),
          },
        ],
      },

      /* FEATURE SECTIONS */

      {
        path: '',
        canActivate: [AuthGuard],
        providers: [FeatureStatsStore],
        children: [
          /* LEADS */
          {
            path: 'leads',
            loadComponent: () => import('./features/leads/leads.component').then(m => m.LeadsComponent),
          },
          {
            // Summary
            path: 'leads/:id',
            loadComponent: () => import('./features/leads/lead/lead.component').then(m => m.LeadSummaryComponent),
          },
          {
            // Edit
            path: 'leads/:id/edit',
            loadComponent: () => import('./features/leads/lead/lead.component').then(m => m.LeadSummaryComponent),
          },
          {
            // Create a new leads.
            path: 'new-lead',
            loadComponent: () => import('./features/leads/new-lead/new-lead.component').then(m => m.NewLeadComponent),
          },

          /* APPS */
          {
            // Applications list.
            path: 'apps',
            loadComponent: () => import('./features/apps/applications.component').then(m => m.ApplicationsComponent),
          },
          {
            // Summary
            path: 'apps/:id',
            loadComponent: () => import('./features/apps/app-summary/app-summary.component').then(m => m.AppSummaryComponent),
          },
          {
            // Edit
            path: 'apps/:id/edit',
            loadComponent: () => import('./features/apps/app-summary/app-summary.component').then(m => m.AppSummaryComponent),
          },
          {
            // Create a new application.
            path: 'new-application',
            loadComponent: () => import('./features/apps/new-application/new-application.component').then(m => m.NewApplicationComponent),
          },

          /* FUNDED */
          {
            path: 'funded',
            loadComponent: () => import('./features/funded/funded.component').then(m => m.FundedComponent),
          },
        ],
      },
    ],
  },
];
