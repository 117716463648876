import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { MenuService } from '../menu.service';

@Component({
  selector: 'app-main-menu-mobile',
  template: `
    <div class="bg-[#22252e] absolute overflow-y-auto md:hidden top-0 right-0 bottom-0 w-[260px]">
      <ul class="mt-3 px-4 text-white">
        <li class="py-3 flex justify-end">
          <h1 class="text-2xl">Main Menu</h1>
          <div class="w-10"></div>
        </li>
        @for (menu of menuService.mainMenu(); track menu; let i = $index) {
          <li class="py-2 text-right">
            @if (!menu.children) {
              <a (click)="menuService.toggleMenu()" [routerLink]="menu.url" class="flex justify-end text-lg hover:text-white">
                {{ menu.title }}
                <div class="w-10 h-9"></div>
              </a>
            }
            @if (menu.children) {
              <a class="flex justify-end hover:text-white text-lg" (click)="toggleSubMenu(menu.title)">
                {{ menu.title }}
                <div class="w-10">
                  <mat-icon [class.rotate-90]="expandedIds()[menu.title]" class="mt-1">
                    {{ expandedIds()[menu.title] ? 'chevron_right' : 'chevron_left' }}
                  </mat-icon>
                </div>
              </a>
            }
            @if (menu.children && expandedIds()[menu.title]) {
              <ul class="my-2">
                @for (child of menu.children; track child) {
                  <li class="py-2">
                    <a (click)="menuService.toggleMenu()" [routerLink]="child.url" class="text-lg flex justify-end hover:text-white">
                      {{ child.title }}

                      <div class="w-10"></div>
                    </a>
                  </li>
                }
              </ul>
            }
          </li>
        }
      </ul>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, MatIcon],
})
export class MainMenuMobileComponent {
  menuService = inject(MenuService);

  readonly expandedIds = signal<Record<string, boolean>>({});

  toggleSubMenu(id: string) {
    this.expandedIds.update(x => {
      x[id] = !x[id];
      return { ...x };
    });
  }
}
