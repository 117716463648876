import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-account-layout',
  standalone: true,
  imports: [IonicModule, RouterOutlet],
  template: ` <router-outlet /> `,
  styles: '',
})
export class AccountLayoutComponent {}
