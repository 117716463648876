import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { MenuService } from '../menu.service';

@Component({
  selector: 'app-main-menu',
  imports: [RouterLink, RouterLinkActive, MatIconModule, NgClass, MatIconButton],
  template: `
    <div class="flex justify-between items-center px-6 md:!px-14 py-3 md:!py-0">
      <a routerLink="/dashboard"> <img src="/assets/images/global/logo_netloan-funding.svg" alt="Logo netLoan Funding" class="h-[60px] block" /></a>

      <button class="md:!hidden" mat-icon-button (click)="menuService.toggleMenu()">
        @if (menuService.isMenuOpen()) {
          <mat-icon svgIcon="closeBlue"></mat-icon>
        } @else {
          <mat-icon svgIcon="menu"></mat-icon>
        }
      </button>

      <div class="hidden md:block">
        <ul class="flex items-center">
          @for (menu of menuService.mainMenu(); track menu) {
            <li [class.has-dropDown]="menu.children" class="hover:bg-[#22252e] hover:text-white">
              <a
                [routerLink]="menu.url"
                [queryParams]="menu.queryParams"
                routerLinkActive="active"
                class="flex items-center gap-3 hover:text-white py-5"
                [ngClass]="{
                  'ps-5 pe-4': menu.children,
                  'px-5': !menu.children,
                }">
                {{ menu.title }}
                @if (menu.children) {
                  <mat-icon class="rotate-90">chevron_right</mat-icon>
                }
              </a>
              @if (menu.children) {
                <div class="dropdown-menu-container absolute w-[200px] py-4 bg-[#22252e]">
                  <ul>
                    @for (child of menu.children; track child) {
                      <li class="hover:bg-[#0096db] text-white p-2 px-4">
                        <a [routerLink]="child.url" [queryParams]="child.queryParams" class="hover:text-white">{{ child.title }}</a>
                      </li>
                    }
                  </ul>
                </div>
              }
            </li>
          }
        </ul>
      </div>
    </div>
  `,
  styles: `
    .active {
      background-color: #0096db;
      color: #ffffff;

      &:hover {
        background-color: #22252e;
      }
    }

    .dropdown-menu-container {
      display: none;
    }

    .has-dropDown:hover .dropdown-menu-container {
      display: inline;
      z-index: 200000;
    }
  `,
})
export class MainMenuComponent {
  menuService = inject(MenuService);
}
